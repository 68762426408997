import React, { useContext, useState } from 'react';
import { BrowserRouter as Router, Routes, Link, Route} from 'react-router-dom';
import Logo from '../images/Monolithlogo.png';
import { AppContext } from '../App';


export const Navbar = () => {

    const [mobileActive, setMobileActive] = useContext(AppContext);

    document.body.style.overflowY = mobileActive ? 'hidden' : 'overlay';




 return (
    <nav>
      <Link to="/">
      <div className = "nav-item nav-title">
          <img src={Logo} alt=""/> <span>Monolith</span>
        </div>
      </Link>

        <div className="nav-links">
            <Link to="/">
                <div className = "nav-item">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path fill="#fff4ff" d="M4 21V9l8-6l8 6v12h-6v-7h-4v7H4Z"/></svg>
                    <span>HOME</span>
                </div>
            </Link>
            <Link to="/products">
                <div className = "nav-item">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path fill="#fff4ff" d="M6 22q-.825 0-1.412-.587T4 20V8q0-.825.588-1.412T6 6h2q0-1.65 1.175-2.825T12 2q1.65 0 2.825 1.175T16 6h2q.825 0 1.413.588T20 8v12q0 .825-.587 1.413T18 22H6Zm4-16h4q0-.825-.587-1.412T12 4q-.825 0-1.412.588T10 6Zm5 5q.425 0 .713-.288T16 10V8h-2v2q0 .425.288.713T15 11Zm-6 0q.425 0 .713-.288T10 10V8H8v2q0 .425.288.713T9 11Z"/></svg>
                    <span>STORE</span>
                </div>
            </Link>
            <Link to="/status">
                <div className = "nav-item">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 20"><path fill="#fff4ff" fill-rule="evenodd" d="M11 16a2 2 0 1 1 0 4a2 2 0 0 1 0-4Zm-6.259-3a2.5 2.5 0 1 1 0 5a2.5 2.5 0 0 1 0-5Zm11.578.5a2 2 0 1 1 0 4a2 2 0 0 1 0-4ZM18.5 9.319a1.5 1.5 0 1 1 0 3a1.5 1.5 0 0 1 0-3ZM2.5 6a2.5 2.5 0 1 1 0 5a2.5 2.5 0 0 1 0-5Zm15.286-.793a1 1 0 1 1 0 2a1 1 0 0 1 0-2ZM8 0a3 3 0 1 1 0 6a3 3 0 0 1 0-6Zm7.5 3a.5.5 0 1 1 0 1a.5.5 0 0 1 0-1Z"/></svg>
                    <span>STATUS</span>
                </div>
            </Link>
            <Link to="/faq">
                <div className = "nav-item">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path fill="#fff4ff" d="M10.6 16q0-2.025.363-2.912T12.5 11.15q1.025-.9 1.563-1.562t.537-1.513q0-1.025-.687-1.7T12 5.7q-1.275 0-1.937.775T9.125 8.05L6.55 6.95q.525-1.6 1.925-2.775T12 3q2.625 0 4.038 1.463t1.412 3.512q0 1.25-.537 2.138t-1.688 2.012Q14 13.3 13.738 13.913T13.475 16H10.6Zm1.4 6q-.825 0-1.412-.587T10 20q0-.825.588-1.412T12 18q.825 0 1.413.588T14 20q0 .825-.587 1.413T12 22Z"/></svg>
                    <span>FAQ</span>
                </div>
            </Link>
            <Link to="https://discord.gg/fivestarsolution">
                <div className = "nav-item">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path fill="#fff4ff" d="M11 21v-2h8v-7.1q0-2.925-2.037-4.962T12 4.9q-2.925 0-4.962 2.038T5 11.9V18H4q-.825 0-1.412-.587T2 16v-2q0-.525.263-.987T3 12.275l.075-1.325q.2-1.7.988-3.15t1.975-2.525Q7.225 4.2 8.762 3.6T12 3q1.7 0 3.225.6t2.725 1.663q1.2 1.062 1.975 2.512t1 3.15l.075 1.3q.475.225.738.675t.262.95v2.3q0 .5-.262.95t-.738.675V19q0 .825-.587 1.413T19 21h-8Zm-2-7q-.425 0-.712-.288T8 13q0-.425.288-.712T9 12q.425 0 .713.288T10 13q0 .425-.288.713T9 14Zm6 0q-.425 0-.712-.288T14 13q0-.425.288-.712T15 12q.425 0 .713.288T16 13q0 .425-.288.713T15 14Zm-8.975-1.55Q5.85 9.8 7.625 7.9T12.05 6q2.225 0 3.913 1.412T18 11.026Q15.725 11 13.813 9.8t-2.938-3.25q-.4 2-1.687 3.563T6.025 12.45Z"/></svg>
                    <span>SUPPORT</span>
                </div>
            </Link>

        </div>

        <div onClick={() => setMobileActive(!mobileActive)} className = {mobileActive ? "mobile-active mobile-btn" : "mobile-btn"}>
            <div className = "bar"></div>
            <div className = "bar"></div>
            <div className = "bar"></div>
    </div>
        <div className = {mobileActive ? "nav-links-mobile nav-links-mobile-active" : "nav-links-mobile"}>
            <Link to="/">
                <div onClick={() => setMobileActive(!mobileActive)} className = "nav-item-mobile">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path fill="#fff4ff" d="M4 21V9l8-6l8 6v12h-6v-7h-4v7H4Z"/></svg>
                    <span>HOME</span>
                </div>
            </Link>
            <Link to="/products">
                <div onClick={() => setMobileActive(!mobileActive)} className = "nav-item-mobile">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path fill="#fff4ff" d="M6 22q-.825 0-1.412-.587T4 20V8q0-.825.588-1.412T6 6h2q0-1.65 1.175-2.825T12 2q1.65 0 2.825 1.175T16 6h2q.825 0 1.413.588T20 8v12q0 .825-.587 1.413T18 22H6Zm4-16h4q0-.825-.587-1.412T12 4q-.825 0-1.412.588T10 6Zm5 5q.425 0 .713-.288T16 10V8h-2v2q0 .425.288.713T15 11Zm-6 0q.425 0 .713-.288T10 10V8H8v2q0 .425.288.713T9 11Z"/></svg>
                    <span>STORE</span>
                </div>
            </Link>
            <Link to="/status">
                <div onClick={() => setMobileActive(!mobileActive)} className = "nav-item-mobile">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 20"><path fill="#fff4ff" fill-rule="evenodd" d="M11 16a2 2 0 1 1 0 4a2 2 0 0 1 0-4Zm-6.259-3a2.5 2.5 0 1 1 0 5a2.5 2.5 0 0 1 0-5Zm11.578.5a2 2 0 1 1 0 4a2 2 0 0 1 0-4ZM18.5 9.319a1.5 1.5 0 1 1 0 3a1.5 1.5 0 0 1 0-3ZM2.5 6a2.5 2.5 0 1 1 0 5a2.5 2.5 0 0 1 0-5Zm15.286-.793a1 1 0 1 1 0 2a1 1 0 0 1 0-2ZM8 0a3 3 0 1 1 0 6a3 3 0 0 1 0-6Zm7.5 3a.5.5 0 1 1 0 1a.5.5 0 0 1 0-1Z"/></svg>
                    <span>STATUS</span>
                </div>
            </Link>
            <Link to="/faq">
                <div onClick={() => setMobileActive(!mobileActive)} className = "nav-item-mobile">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path fill="#fff4ff" d="M10.6 16q0-2.025.363-2.912T12.5 11.15q1.025-.9 1.563-1.562t.537-1.513q0-1.025-.687-1.7T12 5.7q-1.275 0-1.937.775T9.125 8.05L6.55 6.95q.525-1.6 1.925-2.775T12 3q2.625 0 4.038 1.463t1.412 3.512q0 1.25-.537 2.138t-1.688 2.012Q14 13.3 13.738 13.913T13.475 16H10.6Zm1.4 6q-.825 0-1.412-.587T10 20q0-.825.588-1.412T12 18q.825 0 1.413.588T14 20q0 .825-.587 1.413T12 22Z"/></svg>
                    <span>FAQ</span>
                </div>
            </Link>
            <Link to="https://discord.gg/fivestarsolution">
                <div onClick={() => setMobileActive(!mobileActive)} className = "nav-item-mobile">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path fill="#fff4ff" d="M11 21v-2h8v-7.1q0-2.925-2.037-4.962T12 4.9q-2.925 0-4.962 2.038T5 11.9V18H4q-.825 0-1.412-.587T2 16v-2q0-.525.263-.987T3 12.275l.075-1.325q.2-1.7.988-3.15t1.975-2.525Q7.225 4.2 8.762 3.6T12 3q1.7 0 3.225.6t2.725 1.663q1.2 1.062 1.975 2.512t1 3.15l.075 1.3q.475.225.738.675t.262.95v2.3q0 .5-.262.95t-.738.675V19q0 .825-.587 1.413T19 21h-8Zm-2-7q-.425 0-.712-.288T8 13q0-.425.288-.712T9 12q.425 0 .713.288T10 13q0 .425-.288.713T9 14Zm6 0q-.425 0-.712-.288T14 13q0-.425.288-.712T15 12q.425 0 .713.288T16 13q0 .425-.288.713T15 14Zm-8.975-1.55Q5.85 9.8 7.625 7.9T12.05 6q2.225 0 3.913 1.412T18 11.026Q15.725 11 13.813 9.8t-2.938-3.25q-.4 2-1.687 3.563T6.025 12.45Z"/></svg>
                    <span>SUPPORT</span>
                </div>
            </Link>
            <Link to="/tos\">
                <div onClick={() => setMobileActive(!mobileActive)} className = "nav-item-mobile">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 16"><path fill="#fff4ff" d="M7.005 3.1a1 1 0 1 1 1.99 0l-.388 6.35a.61.61 0 0 1-1.214 0zM7 12a1 1 0 1 1 2 0a1 1 0 0 1-2 0"/></svg>
                    <span>TOS</span>
                </div>
            </Link>
          </div>

          <Link to="https://discord.gg/fivestarsolution">
      <button className = "nav-item nav-button">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path fill="#fff4ff" d="M19.27 5.33C17.94 4.71 16.5 4.26 15 4a.09.09 0 0 0-.07.03c-.18.33-.39.76-.53 1.09a16.09 16.09 0 0 0-4.8 0c-.14-.34-.35-.76-.54-1.09c-.01-.02-.04-.03-.07-.03c-1.5.26-2.93.71-4.27 1.33c-.01 0-.02.01-.03.02c-2.72 4.07-3.47 8.03-3.1 11.95c0 .02.01.04.03.05c1.8 1.32 3.53 2.12 5.24 2.65c.03.01.06 0 .07-.02c.4-.55.76-1.13 1.07-1.74c.02-.04 0-.08-.04-.09c-.57-.22-1.11-.48-1.64-.78c-.04-.02-.04-.08-.01-.11c.11-.08.22-.17.33-.25c.02-.02.05-.02.07-.01c3.44 1.57 7.15 1.57 10.55 0c.02-.01.05-.01.07.01c.11.09.22.17.33.26c.04.03.04.09-.01.11c-.52.31-1.07.56-1.64.78c-.04.01-.05.06-.04.09c.32.61.68 1.19 1.07 1.74c.03.01.06.02.09.01c1.72-.53 3.45-1.33 5.25-2.65c.02-.01.03-.03.03-.05c.44-4.53-.73-8.46-3.1-11.95c-.01-.01-.02-.02-.04-.02zM8.52 14.91c-1.03 0-1.89-.95-1.89-2.12s.84-2.12 1.89-2.12c1.06 0 1.9.96 1.89 2.12c0 1.17-.84 2.12-1.89 2.12zm6.97 0c-1.03 0-1.89-.95-1.89-2.12s.84-2.12 1.89-2.12c1.06 0 1.9.96 1.89 2.12c0 1.17-.83 2.12-1.89 2.12z"/></svg>
          DISCORD
        </button>
      </Link>

    </nav>
 )

}