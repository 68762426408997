export const Games = [
    {
        "game": "Rust",
        "gameUrl": "rust"
    },

    {
        "game": "Spoofer",
        "gameUrl": "spoofer"
    },
]

